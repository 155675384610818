import { Modal } from "react-bootstrap";
import { styled } from "styled-components";
const StyledUl = styled.ul`
  list-style: decimal;
  list-style-position: inside;
`;

const StyledLi = styled.li`
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface LinksModalProps {
  links: string[];
  showLinksModal: boolean;
  setShowLinksModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LinksModal = ({
  links,
  showLinksModal,
  setShowLinksModal,
}: LinksModalProps) => (
  <Modal show={showLinksModal} onHide={() => setShowLinksModal(false)}>
    <Modal.Header closeButton className="border-0" />
    <Modal.Body>
      <StyledUl>
        {links.map((link) => (
          <StyledLi key={link}>
            <a target="_blank" rel="noreferrer" href={link}>
              {link}
            </a>
          </StyledLi>
        ))}
      </StyledUl>
    </Modal.Body>
  </Modal>
);
