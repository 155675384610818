import { Alert, Card } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { styled } from "styled-components";

import useArbitrageEventHook from "../../hooks/useArbitrageEventHook";
import { findExchangeById } from "../../utils";
import { StyledTd, TableContainer } from "../misc/TableContainer";

const Container = styled.div`
  height: 600px;
  margin-top: 1rem;
`;

const getColorByIndex = (index: number) => {
  switch (index) {
    case 0:
      return "#FF6666"; // Lighter Red
    case 1:
      return "#6699FF"; // Lighter Blue
    case 2:
      return "#66FF66"; // Lighter Green
    default:
      return "#FFFFFF"; // Or you could return a default color like '#FFFFFF' for white
  }
};

export const ArbitrageInfo = () => {
  const {
    arbitrageEvent: arbitrageInfo,
    isLoading,
    hasError,
    countdown,
  } = useArbitrageEventHook();

  if (hasError) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }

  return (
    <Card>
      <Card.Body>
        <TableContainer
          wrapperClassName="mt-2"
          isEmpty={!arbitrageInfo?.activeWebSockets?.length}
          header={["Instance & Exchange", "Active WS"]}
          countdown={countdown}
          isLoading={isLoading}
        >
          {arbitrageInfo?.activeWebSockets?.length &&
            arbitrageInfo.activeWebSockets.map((info) => (
              <tr key={info.exchangeType}>
                <StyledTd>{findExchangeById(info.exchangeType)?.name}</StyledTd>
                <StyledTd>{info.activeWebSocketCount}</StyledTd>
              </tr>
            ))}
          <tr key="backup">
            <StyledTd className="fw-bold" colSpan={1}>
              Total running arbitrages
            </StyledTd>
            <StyledTd colSpan={2}>
              {arbitrageInfo.activeArbitrageCount}
            </StyledTd>
          </tr>
        </TableContainer>

        <br />
        {arbitrageInfo?.arbitrages?.length &&
          arbitrageInfo.arbitrages.map((info) => (
            <Container className="mb-5" key={info.name}>
              <div className="fw-bold">{info.name}</div>
              <Line
                data={{
                  labels: info.items[0].events.map((value) => value.runId),
                  datasets: info.items.map((item, index) => ({
                    data: item.events.map((value) => value.count),
                    backgroundColor: getColorByIndex(index),
                    borderColor: getColorByIndex(index) + "33",
                    label: item.assetPairText,
                  })),
                }}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      grid: {
                        color: "#ffffff4d",
                      },
                      ticks: {
                        color: "#ffffff99",
                      },
                    },
                    y: {
                      grid: {
                        color: "#ffffff4d",
                      },
                      ticks: {
                        color: "#ffffff99",
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      display: true,
                      position: "top",
                      labels: { color: "#ffffff99" },
                    },
                    tooltip: {
                      callbacks: {
                        // @ts-ignore
                        label: (tooltipItem) => tooltipItem.raw.toFixed(4),
                      },
                    },
                  },
                }}
              />
            </Container>
          ))}
      </Card.Body>
    </Card>
  );
};
