import { useState } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import BalanceViewer from "./balance/BalanceViewer";
import ConfigurationViewer from "./configuration/ConfigurationViewer";
import ExchangeViewer from "./exchange/ExchangeViewer";
import LogsViewer from "./logs/LogsViewer";
import OtherViewer from "./other/OtherViewer";
import PerformanceViewer from "./performance/PerformanceViewer";
import ProfitabilityStoreViewer from "./profitability/ProfitabilityStoreViewer";
import TimelineViewer from "./timeline/TimelineViewer";
import TradesViewer from "./trades/TradesViewer";
import TransferViewer from "./transfer/TransferViewer";

const TabsNav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [key, setKey] = useState<string>(pathname);

  return (
    <Card className="mt-1 mt-sm-3 rounded-0">
      <Card.Header className="px-2 px-sm-3">
        <Tabs
          id="tabs"
          variant="pills"
          activeKey={key}
          onSelect={(k) => {
            if (!k) return;

            setKey(k);
            navigate(k);
          }}
        >
          <Tab eventKey="/" title="Balance" />
          <Tab eventKey="/trades" title="Trades" />
          <Tab eventKey="/logs" title="Logs" />
          <Tab eventKey="/timeline" title="Timeline" />
          <Tab eventKey="/configuration" title="Config" />
          <Tab eventKey="/profitability" title="Profitability" />
          <Tab eventKey="/performance" title="Performance" />
          <Tab eventKey="/transfer" title="Transfer" />
          <Tab eventKey="/exchange" title="Exchange" />
          <Tab eventKey="/other" title="Other" />
        </Tabs>
      </Card.Header>
      <Card.Body className="p-2 p-sm-3">
        <Routes>
          <Route path="" element={<BalanceViewer />} />
          <Route path="trades" element={<TradesViewer />} />
          <Route path="logs" element={<LogsViewer />} />
          <Route path="timeline" element={<TimelineViewer />} />
          <Route path="configuration/*" element={<ConfigurationViewer />} />
          <Route path="profitability" element={<ProfitabilityStoreViewer />} />
          <Route path="performance" element={<PerformanceViewer />} />
          <Route path="transfer" element={<TransferViewer />} />
          <Route path="exchange" element={<ExchangeViewer />} />
          <Route path="other" element={<OtherViewer />} />
        </Routes>
      </Card.Body>
    </Card>
  );
};

export default TabsNav;
