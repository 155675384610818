import { useState } from "react";
import { Card } from "react-bootstrap";

import { DataSettings } from "./DataSettings";
import InfoBadges from "./InfoBadges";
import { LOCAL_STORAGE_SETTINGS_COLLAPSED } from "../../constants";

export const PageHeader = () => {
  const [collapsed, setCollapsed] = useState<boolean>(
    !!localStorage.getItem(LOCAL_STORAGE_SETTINGS_COLLAPSED),
  );

  return (
    <Card className="rounded-0">
      <InfoBadges collapsed={collapsed} setCollapsed={setCollapsed} />

      {!collapsed && <DataSettings />}
    </Card>
  );
};
