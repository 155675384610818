import { Line } from "react-chartjs-2";
import { styled } from "styled-components";

import type { BalanceResponse } from "../../types/Api";
import { floor } from "../../utils";
import Loader from "../misc/Loader";

const Container = styled.div`
  min-height: 600px;
  margin-top: 1rem;
`;

type BalanceChartProps = {
  balance?: BalanceResponse;
  isLoading: boolean;
};

const BalanceChart = ({ balance, isLoading }: BalanceChartProps) => {
  if (isLoading && !balance?.balanceSummaries?.length) {
    return <Loader />;
  }

  return (
    <Container>
      <Line
        data={{
          labels: balance?.balances?.map((item) => item.created),
          datasets: [
            {
              label: "Balance",
              data: balance?.balances?.map((item) => floor(item.balance)),
              fill: false,
              backgroundColor: "#ff0039",
              borderColor: "#ff003933",
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          scales: {
            x: {
              type: "time",
              time: {
                unit: "hour",
                displayFormats: {
                  hour: "dd/MM HH:MM",
                },
              },
              grid: {
                color: "#ffffff4d",
              },
              ticks: {
                color: "#ffffff99",
              },
            },
            y: {
              grid: {
                color: "#ffffff4d",
              },
              ticks: {
                color: "#ffffff99",
              },
            },
          },
        }}
      />
    </Container>
  );
};

export default BalanceChart;
