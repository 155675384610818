import { Button, FormControl, InputGroup } from "react-bootstrap";
import { styled } from "styled-components";
type ConfigurationInputProps = {
  isModified?: boolean;
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  button?: string;
  buttonOnClick?: () => void;
  isDisabled?: boolean;
};

const StyledFormLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConfigurationInput = ({
  isModified,
  label,
  value,
  onChange,
  button,
  buttonOnClick,
  isDisabled,
}: ConfigurationInputProps) => (
  <>
    <StyledFormLabel className={isModified ? "text-danger" : ""}>
      {label}
    </StyledFormLabel>
    <InputGroup>
      <FormControl
        type="text"
        disabled={isDisabled}
        inputMode="decimal"
        value={value || value === -0 ? value : ""}
        // @ts-ignore
        onWheelCapture={(e) => e.target.blur()}
        onChange={onChange}
      />
      {button && (
        <Button variant="outline-secondary" onClick={buttonOnClick}>
          {button}
        </Button>
      )}
    </InputGroup>
  </>
);

export default ConfigurationInput;
