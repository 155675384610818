import { Alert } from "react-bootstrap";

import { TradeItem } from "./TradeItem";
import TradesStatistics from "./TradesStatistics";
import { LOCAL_STORAGE_PAGINATION } from "../../constants";
import { useTradeData } from "../../hooks";
import Countdown from "../misc/Countdown";
import { Pagination } from "../misc/Pagination";

const TradesViewer = () => {
  const {
    tradeData,
    isLoading,
    hasError,
    pageNumber,
    setPageNumber,
    countdown,
  } = useTradeData();

  return (
    <>
      <Countdown isLoading={isLoading} countdown={countdown} />
      {hasError ? (
        <Alert variant="danger">{hasError}</Alert>
      ) : (
        <>
          {tradeData?.pagination?.items.length ? (
            <TradesStatistics tradeData={tradeData} />
          ) : null}
          {tradeData?.pagination?.items.map((trade, index) => (
            <TradeItem key={index} trade={trade} />
          ))}
          {tradeData?.pagination?.items.length ? (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={tradeData.pagination.totalPages}
              currentPageNumber={tradeData.pagination.pageNumber}
              name={LOCAL_STORAGE_PAGINATION.trades}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default TradesViewer;
