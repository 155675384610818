import { Badge, Card } from "react-bootstrap";
import { ChevronDown, ChevronUp, CircleFill } from "react-bootstrap-icons";
import { styled } from "styled-components";

import { LOCAL_STORAGE_SETTINGS_COLLAPSED } from "../../constants";
import {
  useFearAndGreedIndex,
  useGasPrice,
  useInstanceTypeStatus,
  useUserStatus,
} from "../../hooks";
import { InstanceTypeStatus } from "../../types/Api";
import { findInstanceById, findNetworkById } from "../../utils";

const StyledCardHeader = styled(Card.Header)`
  cursor: pointer;
`;

const BadgeWrapper = styled.div`
  display: flex;
  gap: 10px;
  cursor: default;
`;

const StyledBadge = styled(Badge)`
  display: inline-grid;
  text-align: left;
`;

const SizedSpan = styled.span`
  width: 12px;
  display: inline-block;
`;

const StyledDot = styled(CircleFill)`
  margin-right: -3px;
  margin-left: 2px;
  width: 8px;
  margin-top: -3px;
`;

const getBadgeColor = (classification: string) => {
  switch (classification) {
    case "Greed":
      return "text-success";
    case "Fear":
      return "text-danger";
    case "Neutral":
      return "text-warning";
  }
};

interface InfoBadgesProps {
  collapsed: boolean;
  setCollapsed: (collapse: boolean) => void;
}

const InfoBadges = ({ collapsed, setCollapsed }: InfoBadgesProps) => {
  const { instanceTypeInfo } = useInstanceTypeStatus();
  const { gasPrices } = useGasPrice();
  const { userStatuses } = useUserStatus();
  const { fearAndGreedIndex } = useFearAndGreedIndex();

  return (
    <StyledCardHeader
      className="fw-bold d-flex justify-content-between align-items-center"
      onClick={() => {
        if (collapsed) {
          localStorage.removeItem(LOCAL_STORAGE_SETTINGS_COLLAPSED);
        } else {
          localStorage.setItem(LOCAL_STORAGE_SETTINGS_COLLAPSED, "1");
        }
        setCollapsed(!collapsed);
      }}
    >
      <BadgeWrapper onClick={(e) => e.stopPropagation()}>
        <div>
          <div>
            {userStatuses.map((userStatus) => (
              <Badge
                key={userStatus.userAbbreviation}
                bg={userStatus.isActive ? "success" : "warning"}
                className="me-1"
              >
                {userStatus.userAbbreviation}
              </Badge>
            ))}
          </div>
          <div>
            <Badge key="week" className="me-1" bg="dark">
              W {fearAndGreedIndex?.lastWeek?.value}{" "}
              <StyledDot
                className={getBadgeColor(
                  fearAndGreedIndex?.lastWeek?.value_classification,
                )}
              />
            </Badge>
            <Badge key="yesterday" className="me-1" bg="dark">
              Y {fearAndGreedIndex?.yesterday?.value}{" "}
              <StyledDot
                className={getBadgeColor(
                  fearAndGreedIndex?.yesterday?.value_classification,
                )}
              />
            </Badge>
            <Badge key="now" className="me-1" bg="dark">
              N {fearAndGreedIndex?.now?.value}
              <StyledDot
                className={getBadgeColor(
                  fearAndGreedIndex?.now?.value_classification,
                )}
              />
            </Badge>
          </div>
          <div>
            {gasPrices.map((gasPrice) => (
              <StyledBadge
                key={gasPrice.networkPlatform}
                bg="dark"
                className="me-1"
              >
                <span>{findNetworkById(gasPrice.networkPlatform)}</span>
                <span>
                  <SizedSpan>B</SizedSpan> {gasPrice.roundedBaseFeePerGas}
                </span>
                <span>
                  <SizedSpan>P</SizedSpan>{" "}
                  {gasPrice.roundedMaxPriorityFeePerGas}
                </span>
                <span>
                  <SizedSpan>M</SizedSpan> {gasPrice.roundedMaxFeePerGas}
                </span>
              </StyledBadge>
            ))}
          </div>
          <div>
            {instanceTypeInfo.map((info) => {
              let statusColor;

              switch (info.status) {
                case InstanceTypeStatus.Running:
                  statusColor = "success";
                  break;
                case InstanceTypeStatus.Inactive:
                  statusColor = "warning";
                  break;
                case InstanceTypeStatus.Error:
                default:
                  statusColor = "danger";
                  break;
              }

              return (
                <Badge key={info.type} bg={statusColor} className="me-1">
                  {findInstanceById(info.type)}
                </Badge>
              );
            })}
          </div>
          <div />
        </div>
      </BadgeWrapper>
      {collapsed ? <ChevronDown /> : <ChevronUp />}
    </StyledCardHeader>
  );
};

export default InfoBadges;
