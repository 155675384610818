import { useEffect, useState } from "react";
import { Form, Pagination as PaginationBootstrap } from "react-bootstrap";
import { styled } from "styled-components";
const Wrapper = styled.div`
  height: 31px;
  display: flex;
  justify-content: space-between;
`;

const StyledFormControl = styled(Form.Control)`
  width: 70px;
`;

type PaginationProps = {
  name: string;
  pageNumber: number;
  currentPageNumber: number;
  totalPages: number;
  setPageNumber: (pageNumber: number) => void;
};

export const Pagination = ({
  name,
  pageNumber,
  setPageNumber,
  currentPageNumber,
  totalPages,
}: PaginationProps) => {
  const [value, setValue] = useState(localStorage.getItem(name) || undefined);

  useEffect(() => {
    if (Number(value) >= 1) {
      localStorage.setItem(name, parseInt(value || "1", 10).toString());
    }
  }, [value]);

  return (
    <Wrapper className="d-flex">
      <div />
      <PaginationBootstrap size="sm" className="m-0">
        <PaginationBootstrap.First
          disabled={pageNumber === 1}
          onClick={() => setPageNumber(1)}
        />
        <PaginationBootstrap.Prev
          disabled={pageNumber === 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        />
        <PaginationBootstrap.Item active>
          {currentPageNumber}
        </PaginationBootstrap.Item>
        <PaginationBootstrap.Next
          disabled={pageNumber === totalPages}
          onClick={() => setPageNumber(pageNumber + 1)}
        />
        <PaginationBootstrap.Last
          disabled={pageNumber === totalPages}
          onClick={() => setPageNumber(totalPages)}
        />
      </PaginationBootstrap>
      <div>
        <StyledFormControl
          className="align-self-start"
          size="sm"
          type="number"
          value={value}
          // @ts-ignore
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </Wrapper>
  );
};
