import {
  addDays,
  endOfMonth,
  endOfWeek,
  roundToNearestMinutes,
  startOfMonth,
  startOfWeek,
  subDays,
  subHours,
  subMinutes,
  subMonths,
} from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { styled } from "styled-components";

import { DataContext } from "../../context/Data";
import { getIsoDate, getTodayUTC, timeZone } from "../../utils";

const StyledInputGroupText = styled(InputGroup.Text)`
  width: 3.5rem;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  flex-wrap: wrap;
`;

const createDateOption = (label: string, from: Date, to?: Date) => ({
  label,
  onClickFromDate: fromZonedTime(from, timeZone),
  onClickToDate: to ? fromZonedTime(to, timeZone) : addDays(getTodayUTC(), 1),
});

export const dateOptions = [
  createDateOption("Last 10 minutes", subMinutes(new Date(), 10)),
  createDateOption("Last 30 minutes", subMinutes(new Date(), 30)),
  createDateOption("Last hour", subHours(new Date(), 1)),
  createDateOption("Last 3 hours", subHours(new Date(), 3)),
  createDateOption("Last 6 hours", subHours(new Date(), 6)),
  createDateOption("Last 12 hours", subHours(new Date(), 12)),
  createDateOption("Today", getTodayUTC()),
  createDateOption("Yesterday", subDays(getTodayUTC(), 1), getTodayUTC()),
  createDateOption("Last 24 hours", subHours(new Date(), 24)),
  createDateOption("Last 3 days", subHours(new Date(), 72)),
  createDateOption("Last 7 days", subHours(new Date(), 168)),
  createDateOption(
    "This week",
    startOfWeek(getTodayUTC(), { weekStartsOn: 1 }),
    endOfWeek(getTodayUTC(), { weekStartsOn: 1 }),
  ),
  createDateOption(
    "Previous week",
    subDays(startOfWeek(getTodayUTC(), { weekStartsOn: 1 }), 7),
    subDays(endOfWeek(getTodayUTC(), { weekStartsOn: 1 }), 7),
  ),
  createDateOption(
    "This month",
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ),
  createDateOption(
    "Previous month",
    subMonths(startOfMonth(getTodayUTC()), 1),
    endOfMonth(subMonths(getTodayUTC(), 1)),
  ),
  createDateOption("Last month", subMonths(new Date(), 1)),
  createDateOption("All time", new Date("2021-05-06 23:41:54")),
];

interface DropdownWithLabelProps {
  label: string;
  options?: Array<{ label: string; value: any }>;
  onSelect: (value: any) => void;
  selectedValue?: string;
}

export const DropdownWithLabel = ({
  label,
  options,
  onSelect,
  selectedValue,
}: DropdownWithLabelProps) => (
  <Dropdown className="me-2 mb-2">
    <Dropdown.Toggle className="px-3" variant="secondary" size="sm">
      {selectedValue || label}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {options?.map((option) => (
        <Dropdown.Item
          key={option.label}
          onClick={() => onSelect(option.value)}
        >
          {option.label}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

const DATETIME_SETTINGS_LABEL = "Date & Time";

export const DataSettings = () => {
  const {
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    wallets,
    chosenWallet,
    setChosenWallet,
    arbitrages,
    chosenArbitrage,
    setChosenArbitrage,
  } = useContext(DataContext);

  const [pickerFromDate, setPickerFromDate] = useState<string>(fromDate);
  const [pickerToDate, setPickerToDate] = useState<string>(toDate);
  const [datetimeSettingsLabel, setDatetimeSettingsLabel] = useState<string>(
    DATETIME_SETTINGS_LABEL,
  );

  useEffect(() => {
    setPickerFromDate(fromDate);
    setPickerToDate(toDate);
  }, [fromDate, toDate]);

  const applyDateSettings = () => {
    setFromDate(pickerFromDate);
    setToDate(pickerToDate);
    setDatetimeSettingsLabel(DATETIME_SETTINGS_LABEL);
  };

  const resetDateSettings = () => {
    setFromDate(getIsoDate(new Date()));
    setToDate(getIsoDate(new Date()));
    setChosenWallet(null);
    setChosenArbitrage(null);
  };

  return (
    <Card.Body className="p-2 p-md-3">
      <StyledButtonGroup>
        <DropdownWithLabel
          label={datetimeSettingsLabel}
          options={dateOptions.map((option) => ({
            label: option.label,
            value: option,
          }))}
          onSelect={(option) => {
            setFromDate(
              getIsoDate(roundToNearestMinutes(option.onClickFromDate)),
            );
            setToDate(getIsoDate(roundToNearestMinutes(option.onClickToDate)));
            setDatetimeSettingsLabel(option.label);
          }}
        />

        <DropdownWithLabel
          label="Wallet"
          options={wallets?.map((wallet) => ({
            label: wallet.name,
            value: wallet,
          }))}
          selectedValue={chosenWallet?.name}
          onSelect={setChosenWallet}
        />

        <DropdownWithLabel
          label="Arbitrage"
          options={arbitrages?.map((arbitrage) => ({
            label: arbitrage.name || "",
            value: arbitrage,
          }))}
          selectedValue={chosenArbitrage?.name || ""}
          onSelect={setChosenArbitrage}
        />
      </StyledButtonGroup>

      <Row sm={12}>
        <Col md={4} lg={3} className="mb-2">
          <InputGroup size="sm">
            <StyledInputGroupText>From</StyledInputGroupText>
            <Form.Control
              type="datetime-local"
              value={pickerFromDate}
              onChange={(e) => setPickerFromDate(e.target.value)}
              max={getIsoDate(new Date())}
            />
          </InputGroup>
        </Col>
        <Col md={4} lg={3} className="mb-2">
          <InputGroup size="sm">
            <StyledInputGroupText>To</StyledInputGroupText>
            <Form.Control
              type="datetime-local"
              value={pickerToDate}
              onChange={(e) => setPickerToDate(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button size="sm" variant="success" onClick={applyDateSettings}>
            Apply
          </Button>
          <Button
            size="sm"
            variant="danger"
            className="mx-2"
            onClick={resetDateSettings}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </Card.Body>
  );
};
