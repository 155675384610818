import { AllowanceResponse } from "./AllowanceResponse";
import GatewaysStatus from "./GatewaysStatus";

const OtherViewer = () => {
  return (
    <>
      <GatewaysStatus />
      <AllowanceResponse />
    </>
  );
};

export default OtherViewer;
