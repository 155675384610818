import { Button } from "react-bootstrap";
import { ArrowLeftRight } from "react-bootstrap-icons";
import { styled } from "styled-components";
const SwapButtonWrapper = styled(Button)`
  width: 40px;
`;

export const SwapButton = ({
  onClick,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled?: boolean;
}) => (
  <SwapButtonWrapper
    variant="outline-secondary"
    onClick={onClick}
    disabled={isDisabled}
  >
    <ArrowLeftRight />
  </SwapButtonWrapper>
);
