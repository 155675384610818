import { Modal } from "react-bootstrap";

import { DataTable } from "./DataTable";
import {
  TradeAdditionalDataTypeEnum,
  TradeItemTransactionAdditionalDataResponse,
} from "../../types/Api";
import { findTradeAdditionalDataTypeById } from "../../utils";

interface DataModalProps {
  data: TradeItemTransactionAdditionalDataResponse[];
  showDataModal: boolean;
  setShowDataModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DataModal = ({
  data,
  showDataModal,
  setShowDataModal,
}: DataModalProps) => (
  <Modal show={showDataModal} onHide={() => setShowDataModal(false)}>
    <Modal.Header closeButton className="border-0" />
    <Modal.Body>
      {data.map((item, index) => (
        <div key={index}>
          <span>{findTradeAdditionalDataTypeById(item.type)}</span>
          {item.type !== TradeAdditionalDataTypeEnum.OrderBookSnapshot && (
            <pre>{JSON.stringify(JSON.parse(item.data), undefined, 2)}</pre>
          )}

          {item.type === TradeAdditionalDataTypeEnum.OrderBookSnapshot && (
            <DataTable json={item.data} />
          )}
          {index + 1 < data.length && <hr />}
        </div>
      ))}
    </Modal.Body>
  </Modal>
);
