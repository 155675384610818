import { Spinner } from "react-bootstrap";
import {
  Check2Circle,
  ChevronDown,
  ChevronUp,
  XLg,
} from "react-bootstrap-icons";
import { styled } from "styled-components";

import { isArbitrageResponse } from "./configUtils";
import {
  ArbitrageAssetPairWalletResponse,
  ArbitrageResponse,
} from "../../types/Api";
import { findFeeAssetTypeNameById } from "../../utils";

const StyledHeading = styled.div`
  cursor: pointer;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  margin-left: 4px;
  align-items: center;
`;

interface TradeConfigurationHeaderProps {
  arbitrage: ArbitrageResponse | ArbitrageAssetPairWalletResponse;
  isVisible: boolean;
  handleVisibilityChange: () => void;
  updateHasError?: boolean;
  updateIsLoading?: boolean;
  updateIsDone?: boolean;
}

export const TradeConfigurationHeader = ({
  arbitrage,
  isVisible,
  handleVisibilityChange,
  updateHasError,
  updateIsDone,
  updateIsLoading,
}: TradeConfigurationHeaderProps) => (
  <StyledHeading
    onClick={handleVisibilityChange}
    className="d-flex align-items-center"
  >
    {isVisible ? (
      <ChevronUp className="mx-1" />
    ) : (
      <ChevronDown className="mx-1" />
    )}
    {isArbitrageResponse(arbitrage) ? (
      <Wrapper>
        <div className="d-flex align-items-center">
          {arbitrage.name}{" "}
          {updateIsLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className="ms-1"
            />
          )}
          {updateIsDone && <Check2Circle className="ms-1" />}
          {updateHasError && <XLg className="ms-1" />}
        </div>
      </Wrapper>
    ) : (
      `${arbitrage.wallet.name}: ${arbitrage.assetPair.amountAsset.name}${
        arbitrage.assetPair.isEqualized ? "⌿" : "/"
      }${arbitrage.assetPair.priceAsset.name} (${findFeeAssetTypeNameById(
        arbitrage.feeType,
      )})${arbitrage.eventTrigger ? "" : " 🛑"}`
    )}
  </StyledHeading>
);
