import { Alert, Card, Form } from "react-bootstrap";
import { Grid, GridFill, Trash2 } from "react-bootstrap-icons";
import styled from "styled-components";

import { LOCAL_STORAGE_PAGINATION } from "../../constants";
import { useLogs } from "../../hooks";
import { LogLevel, SourceLevel } from "../../hooks/useLogsHook";
import { LogResponse } from "../../types/Api";
import { formatDateTime } from "../../utils";
import Countdown from "../misc/Countdown";
import { TableContainer } from "../misc/TableContainer";

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledFormCheck = styled(Form.Check)`
  white-space: nowrap;
  &:not(:last-of-type) {
    padding-right: 21px;
  }
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0;
  user-select: none;

  label {
    padding: 10px;
    margin: -10px;
  }
`;

const StyledCardHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
`;

const getLogLevelColor = (level: LogResponse["level"]): string => {
  switch (level) {
    case LogLevel.Information:
      return "text-muted";
    case LogLevel.Warning:
      return "text-info";
    case LogLevel.Error:
      return "text-warning";
    case LogLevel.Fatal:
      return "text-danger";
    default:
      return "";
  }
};

const LogsTable: React.FC = () => {
  const {
    logs,
    isLoading,
    hasError,
    countdown,
    pageNumber,
    setPageNumber,
    deleteLogs,
    levels,
    setLevels,
    sources,
    setSources,
  } = useLogs();

  const handleLevelToggle = (level: LogLevel) => {
    setLevels((prevLevels) =>
      prevLevels.includes(level)
        ? prevLevels.filter((lvl) => lvl !== level)
        : [...prevLevels, level],
    );
  };

  const handleSourceToggle = (source: SourceLevel) => {
    setSources((prevSources) =>
      prevSources.includes(source)
        ? prevSources.filter((src) => src !== source)
        : [...prevSources, source],
    );
  };

  return (
    <>
      <Wrapper>
        <Card className="mb-2 d-inline-flex">
          <StyledCardHeader>
            <div className="d-flex align-items-center">Levels</div>
            <div className="d-flex align-items-center">
              <GridFill
                onClick={() => setLevels(Object.values(LogLevel))}
                className="mx-2"
                style={{ cursor: "pointer" }}
              />
              <Grid
                onClick={() => setLevels([])}
                className="mx-2"
                style={{ cursor: "pointer" }}
              />
            </div>
          </StyledCardHeader>
          <Card.Body className="p-2 px-3">
            <div className="d-flex align-items-center flex-wrap">
              {Object.values(LogLevel).map((level) => (
                <StyledFormCheck
                  key={level}
                  type="checkbox"
                  label={level}
                  id={level}
                  checked={levels.includes(level)}
                  onChange={() => handleLevelToggle(level)}
                />
              ))}
            </div>
          </Card.Body>
        </Card>

        <Card className="mb-2 d-inline-flex">
          <StyledCardHeader>
            <div>Sources</div>
            <div className="d-flex align-items-center">
              <GridFill
                onClick={() => setSources(Object.values(SourceLevel))}
                className="mx-2"
                style={{ cursor: "pointer" }}
              />
              <Grid
                onClick={() => setSources([])}
                className="mx-2"
                style={{ cursor: "pointer" }}
              />
            </div>
          </StyledCardHeader>
          <Card.Body className="p-2 px-3">
            <div className="d-flex align-items-center flex-wrap">
              {Object.values(SourceLevel).map((source) => (
                <StyledFormCheck
                  key={source}
                  type="checkbox"
                  label={source}
                  id={source}
                  checked={sources.includes(source)}
                  onChange={() => handleSourceToggle(source)}
                />
              ))}
            </div>
          </Card.Body>
        </Card>
      </Wrapper>

      <Countdown isLoading={isLoading} countdown={countdown} />

      {hasError ? (
        <Alert variant="danger">{hasError}</Alert>
      ) : (
        <TableContainer
          isLoading={isLoading}
          header={[
            [
              <div key="trash" className="align-items-center">
                Created&nbsp;
                <Trash2 onClick={() => deleteLogs()} className="mb-1" />
              </div>,
              "130px",
            ],
            ["Source", "65px"],
            ["Level", "70px"],
            ["Error message", "600px"],
          ]}
          isEmpty={!logs.items?.length}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalPages={logs.totalPages}
          currentPageNumber={logs.pageNumber}
          paginationName={LOCAL_STORAGE_PAGINATION.logs}
        >
          {logs.items?.map((log, index) => (
            <tr key={index}>
              <td>{formatDateTime(log.created!)}</td>
              <td>{log.source}</td>
              <td
                className={`${getLogLevelColor(log.level)}`}
                onClick={() => alert(log.exception)}
              >
                {log.level}
              </td>
              <td>{log.message}</td>
            </tr>
          ))}
        </TableContainer>
      )}
    </>
  );
};

export default LogsTable;
