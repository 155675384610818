import { Pause, PlayFill } from "react-bootstrap-icons";
import { styled } from "styled-components";

import { ArbitrageResponse } from "../../types/Api";
import ConfigurationTurnOnOff from "../misc/ConfigurationTurnOnOff";

const Wrapper = styled.div`
  gap: 6px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

interface TradeConfigurationTogglesProps {
  arbitrage: ArbitrageResponse;
  previousArbitrage: ArbitrageResponse;
  onPauseToggle: () => Promise<void>;
  onEnableToggle: () => Promise<void>;
}

export const TradeConfigurationToggles = ({
  arbitrage,
  previousArbitrage,
  onPauseToggle,
  onEnableToggle,
}: TradeConfigurationTogglesProps) => (
  <Wrapper className="d-flex justify-content-between">
    <ConfigurationTurnOnOff
      isModified={arbitrage.isPaused != previousArbitrage.isPaused}
      value={!arbitrage.isPaused}
      checkedHandleLabel={<PlayFill />}
      uncheckedHandleLabel={<Pause />}
      onChange={onPauseToggle}
    />

    <ConfigurationTurnOnOff
      isModified={arbitrage.isEnabled != previousArbitrage.isEnabled}
      value={arbitrage.isEnabled}
      checkedHandleLabel="On"
      uncheckedHandleLabel="Off"
      onChange={onEnableToggle}
    />
  </Wrapper>
);
