import { Card, Spinner } from "react-bootstrap";
import { styled } from "styled-components";
const Wrapper = styled.div<{ height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "120px")};
`;

const StyledSpinner = styled(Spinner)`
  width: 60px;
  height: 60px;
`;

type LoaderProps = {
  height?: string;
};

const Loader = ({ height }: LoaderProps) => (
  <Card className="mb-3 rounded-0">
    <Wrapper height={height}>
      <StyledSpinner size="sm" animation="border" variant="primary" />
    </Wrapper>
  </Card>
);

export default Loader;
