import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { ArrowDownUp } from "react-bootstrap-icons";
import { styled } from "styled-components";

import { DOLLAR_DECIMALS } from "../../constants";
import { useExchange, useWalletBalance } from "../../hooks";
import {
  AssetResponse,
  OrderSideEnum,
  SwapPriceResponse,
  SwapRequest,
  SwapWhitelistResponse,
  WalletResponse,
} from "../../types/Api";
import {
  getAssetPairId,
  getOrderSide,
  isMobile,
  parseAmount,
  sleep,
} from "../../utils";
import Loader from "../misc/Loader";

const StyledInputGroupText = styled(InputGroup.Text)`
  font-weight: bold;
  width: 3.6rem;
  @media (min-width: 500px) {
    width: 7rem;
  }
`;

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  min-width: 50px;
`;

const PriceButton = styled(Button)`
  text-transform: capitalize;
  @media (max-width: 500px) {
    padding-left: 0.2rem !important;
    padding-right: 0.2rem !important;
    font-size: 0.8rem !important;
  }
`;

const BottomButton = styled(Button)`
  width: 140px;
`;

const getAssetOptions = (
  assetA?: AssetResponse,
  assetB?: AssetResponse,
  item?: SwapWhitelistResponse,
) => {
  if (!item || !item.assetPairs) return [];

  const chosenAsset = assetA || assetB;

  if (!chosenAsset) return item.availableAssets;

  return item.assetPairs
    .map((asset) => {
      if (asset.amountAsset.id === chosenAsset.id) return asset.priceAsset;
      if (asset.priceAsset.id === chosenAsset.id) return asset.amountAsset;
    })
    .filter((itemAsset) => itemAsset);
};

const getPrice = (priceType: string, prices: SwapPriceResponse["asks"]) => {
  switch (priceType) {
    case "firstPrice":
      return prices.firstPrice;
    case "averagePrice":
      return prices.averagePrice;
    case "lastPrice":
    default:
      return prices.lastPrice;
  }
};

const getPriceBidAsk = (
  prices: SwapPriceResponse,
  orderSide: OrderSideEnum,
) => {
  const firstPriceAsk = getPrice("firstPrice", prices.asks);
  const firstPriceBid = getPrice("firstPrice", prices.bids);
  const deviation = firstPriceAsk - firstPriceBid;
  if (orderSide === OrderSideEnum.Sell) {
    return deviation <= 0.0001 ? firstPriceAsk : firstPriceAsk - 0.0001;
  }
  return deviation <= 0.0001 ? firstPriceBid : firstPriceBid + 0.0001;
};

enum Setter {
  Wallet,
  WhitelistItem,
  AssetPairId,
  AssetA,
  AssetB,
  Price,
  CancelTime,
  Amount,
}

type ExchangeCardProps = {
  refreshData: () => void;
};

const ExchangeCard = ({ refreshData }: ExchangeCardProps) => {
  const {
    exchange,
    isLoadingDone,
    isLoading,
    hasError,
    getExchangePrices,
    prices,
    pricesIsLoading,
    pricesHasError,
    resetPrices,
    fetchExchangeWhitelist,
    exchangeWhitelistIsLoading,
    exchangeWhitelistHasError,
    exchangeWhitelist,
  } = useExchange();

  const {
    walletBalances,
    fetchWalletBalances,
    isLoading: walletBalancesIsLoading,
  } = useWalletBalance();

  useEffect(() => {
    fetchExchangeWhitelist();
  }, []);

  const [wallet, setWallet] = useState<WalletResponse>();
  const [whitelistItem, setWhitelistItem] = useState<SwapWhitelistResponse>();

  const [assetA, setAssetA] = useState<AssetResponse>();
  const [assetB, setAssetB] = useState<AssetResponse>();

  const [amount, setAmount] = useState<string>("0");
  const [price, setPrice] = useState<string>("0");

  const [cancelTime, setCancelTime] = useState<number | null>(5);

  if (exchangeWhitelistIsLoading) return <Loader height="446px" />;
  if (exchangeWhitelistHasError) {
    return (
      <Alert className="mt-3" variant="danger">
        {exchangeWhitelistHasError}
      </Alert>
    );
  }

  const reset = (keepStates: Setter[]) => {
    if (!keepStates.includes(Setter.AssetA)) setAssetA(undefined);
    if (!keepStates.includes(Setter.AssetB)) setAssetB(undefined);
    if (!keepStates.includes(Setter.CancelTime)) setCancelTime(5);
    if (!keepStates.includes(Setter.WhitelistItem)) setWhitelistItem(undefined);
    if (!keepStates.includes(Setter.Wallet)) setWallet(undefined);
    if (!keepStates.includes(Setter.Amount)) setAmount("0");
    if (!keepStates.includes(Setter.Price)) setPrice("0");
    if (!keepStates.includes(Setter.Price)) resetPrices();
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Body className="px-2 px-sm-3">
          <Row>
            <Col lg={6}>
              <InputGroup size="sm" className="mb-3">
                <StyledInputGroupText>
                  {!isMobile() ? "Exchange" : "Ex"}
                </StyledInputGroupText>
                <Dropdown>
                  <StyledDropdownToggle variant="outline-secondary">
                    {wallet?.name}
                  </StyledDropdownToggle>

                  <Dropdown.Menu>
                    {exchangeWhitelist!.map((option) => (
                      <Dropdown.Item
                        key={option.wallet.id}
                        onClick={() => {
                          setWhitelistItem(option);
                          setWallet(option.wallet);
                          reset([
                            Setter.WhitelistItem,
                            Setter.Wallet,
                            Setter.CancelTime,
                          ]);
                        }}
                      >
                        {option.wallet.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </InputGroup>

              <InputGroup size="sm" className="mb-3">
                <StyledInputGroupText>Sell</StyledInputGroupText>
                <Dropdown>
                  <StyledDropdownToggle
                    variant="outline-secondary"
                    disabled={!wallet}
                  >
                    {assetA?.name}
                  </StyledDropdownToggle>

                  <Dropdown.Menu>
                    {getAssetOptions(undefined, assetB, whitelistItem)?.map(
                      (asset) =>
                        asset && (
                          <Dropdown.Item
                            key={asset.name}
                            onClick={() => {
                              setAssetA(asset);
                              reset([
                                Setter.WhitelistItem,
                                Setter.Wallet,
                                Setter.AssetA,
                                Setter.CancelTime,
                              ]);
                            }}
                          >
                            {asset.name}
                          </Dropdown.Item>
                        ),
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {assetA && assetB && (
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      const tempAssetA = assetA;

                      setAssetA(assetB);
                      setAssetB(tempAssetA);
                    }}
                  >
                    <ArrowDownUp />
                  </Button>
                )}
              </InputGroup>
              <InputGroup size="sm" className="mb-3">
                <StyledInputGroupText>Buy</StyledInputGroupText>
                <Dropdown>
                  <StyledDropdownToggle
                    variant="outline-secondary"
                    disabled={!wallet}
                  >
                    {assetB?.name}
                  </StyledDropdownToggle>

                  <Dropdown.Menu>
                    {getAssetOptions(assetA, undefined, whitelistItem)?.map(
                      (asset) =>
                        asset && (
                          <Dropdown.Item
                            key={asset.name}
                            onClick={() => {
                              setAssetB(asset);
                              reset([
                                Setter.WhitelistItem,
                                Setter.Wallet,
                                Setter.AssetA,
                                Setter.AssetB,
                                Setter.CancelTime,
                              ]);
                            }}
                          >
                            {asset.name}
                          </Dropdown.Item>
                        ),
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {assetA && assetB && (
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      const tempAssetA = assetA;

                      setAssetA(assetB);
                      setAssetB(tempAssetA);
                    }}
                  >
                    <ArrowDownUp />
                  </Button>
                )}
              </InputGroup>
              <InputGroup size="sm" className="mb-3">
                <StyledInputGroupText>
                  {assetA && assetB ? (
                    getOrderSide(whitelistItem, assetA, assetB) ===
                    OrderSideEnum.Buy ? (
                      <span className="text-success">
                        {!isMobile() ? "Buy " : ""}
                        {assetB?.name}
                      </span>
                    ) : (
                      <span className="text-warning">
                        {!isMobile() ? "Sell " : ""}
                        {assetA?.name}
                      </span>
                    )
                  ) : !isMobile() ? (
                    "Amount"
                  ) : (
                    "Σ"
                  )}
                </StyledInputGroupText>
                <FormControl
                  type="text"
                  inputMode="decimal"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  disabled={!assetA}
                />
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    const assetPairId = getAssetPairId(
                      whitelistItem,
                      assetA,
                      assetB,
                    );

                    if (!assetPairId || !wallet) return;

                    getExchangePrices(
                      assetPairId.id,
                      wallet.id,
                      parseAmount(amount),
                    );
                  }}
                  disabled={
                    !assetA || !assetB || amount === "" || amount === "0"
                  }
                >
                  Quote price{" "}
                  {pricesIsLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                    />
                  )}
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={async () => {
                    if (!assetA || !assetB || !wallet) return;

                    await fetchWalletBalances(wallet?.id);

                    const balance = walletBalances.find(
                      (wallet) => wallet.asset.id === assetA.id,
                    )?.balance;

                    if (
                      balance &&
                      getOrderSide(whitelistItem, assetA, assetB) ===
                        OrderSideEnum.Buy
                    ) {
                      // @ts-ignore
                      balance = balance / assetB.price;
                    }

                    if (balance) {
                      setAmount(balance.toString());
                    }
                  }}
                  disabled={!assetA || !assetB}
                >
                  {getOrderSide(whitelistItem, assetA, assetB) ===
                  OrderSideEnum.Buy
                    ? "~Max"
                    : "Max"}{" "}
                  {walletBalancesIsLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                    />
                  )}
                </Button>
              </InputGroup>

              <InputGroup size="sm" className="mb-3">
                <StyledInputGroupText>Price</StyledInputGroupText>
                <FormControl
                  type="text"
                  inputMode="decimal"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  disabled={!assetA || !assetB}
                />
                {prices?.asks && prices?.bids && (
                  <PriceButton
                    variant="outline-secondary"
                    className={!isMobile() ? "px-1" : ""}
                    onClick={() => {
                      if (!whitelistItem) return;

                      const orderSide = getOrderSide(
                        whitelistItem,
                        assetA,
                        assetB,
                      );

                      setPrice(getPriceBidAsk(prices, orderSide!).toString());
                    }}
                  >
                    {assetA &&
                    assetB &&
                    getOrderSide(whitelistItem, assetA, assetB) ===
                      OrderSideEnum.Sell ? (
                      <span className="text-success">
                        F{getPriceBidAsk(prices, OrderSideEnum.Sell).toFixed(4)}
                      </span>
                    ) : (
                      <span className="text-warning">
                        F{getPriceBidAsk(prices, OrderSideEnum.Buy).toFixed(4)}
                      </span>
                    )}
                  </PriceButton>
                )}
                {prices?.asks &&
                  prices?.bids &&
                  ["firstPrice", "averagePrice", "lastPrice"].map(
                    (priceType) => (
                      <PriceButton
                        key={priceType}
                        variant="outline-secondary"
                        className={!isMobile() ? "px-1" : ""}
                        onClick={() => {
                          if (!whitelistItem) return;

                          const orderSide = getOrderSide(
                            whitelistItem,
                            assetA,
                            assetB,
                          );

                          if (orderSide === OrderSideEnum.Buy) {
                            setPrice(
                              getPrice(priceType, prices.asks).toString(),
                            );
                          } else if (orderSide === OrderSideEnum.Sell) {
                            setPrice(
                              getPrice(priceType, prices.bids).toString(),
                            );
                          }
                        }}
                      >
                        {assetA &&
                        assetB &&
                        getOrderSide(whitelistItem, assetA, assetB) ===
                          OrderSideEnum.Buy ? (
                          <span className="text-success">
                            {priceType[0]}
                            {getPrice(priceType, prices.asks).toFixed(4)}
                          </span>
                        ) : (
                          <span className="text-warning">
                            {priceType[0]}
                            {getPrice(priceType, prices.bids).toFixed(4)}
                          </span>
                        )}
                      </PriceButton>
                    ),
                  )}
              </InputGroup>
              <InputGroup size="sm" className="mb-3">
                <StyledInputGroupText>
                  {assetA && assetB ? (
                    getOrderSide(whitelistItem, assetA, assetB) ===
                    OrderSideEnum.Buy ? (
                      <span className="text-warning">
                        {!isMobile() ? "Sell " : ""}
                        {assetA?.name}
                      </span>
                    ) : (
                      <span className="text-success">
                        {" "}
                        {!isMobile() ? "Buy " : ""}
                        {assetB?.name}
                      </span>
                    )
                  ) : !isMobile() ? (
                    "Amount"
                  ) : (
                    "Σ"
                  )}
                </StyledInputGroupText>
                <FormControl
                  value={
                    amount !== "0" && price !== "0"
                      ? (parseAmount(amount) * parseAmount(price)).toFixed(
                          DOLLAR_DECIMALS,
                        )
                      : "0"
                  }
                  disabled={true}
                />
              </InputGroup>
              <InputGroup size="sm" className="mb-3">
                <StyledInputGroupText>Expire</StyledInputGroupText>
                <FormControl
                  value={cancelTime || ""}
                  onChange={(e) =>
                    setCancelTime(Number.parseInt(e.target.value))
                  }
                  disabled={cancelTime === -1}
                />
                <InputGroup.Text>Unlimited</InputGroup.Text>
                <InputGroup.Checkbox
                  checked={cancelTime === -1}
                  onChange={() => setCancelTime(cancelTime !== 5 ? 5 : -1)}
                />
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="mb-3 d-flex justify-content-center">
        {/* @ts-ignore */}
        <BottomButton
          className="px-4 mx-2"
          variant="success"
          disabled={!assetA || !assetB || !amount || !price || isLoading}
          onClick={async () => {
            if (!wallet || !assetA || !assetB) return;

            const orderSide = getOrderSide(whitelistItem, assetA, assetB);

            const assetPairId = getAssetPairId(whitelistItem, assetA, assetB);

            if (!orderSide || !assetPairId) return;

            const exchangeData: SwapRequest = {
              cancellableTimeInSeconds: cancelTime === -1 ? null : cancelTime,
              walletId: wallet.id,
              assetPairId: assetPairId.id,
              orderSide,
              amount: parseAmount(amount),
              price: typeof price === "string" ? parseAmount(price) : price,
            };

            try {
              await exchange(exchangeData, undefined, "swap");

              refreshData();

              await sleep(5000);
              refreshData();
            } catch {}
          }}
        >
          Exchange{" "}
          {isLoading && (
            <Spinner as="span" animation="border" size="sm" role="status" />
          )}
        </BottomButton>
        <BottomButton
          className="px-4 mx-2"
          variant="danger"
          onClick={() => reset([])}
        >
          Reset
        </BottomButton>
      </div>

      {isLoadingDone && <Alert variant="success">{isLoadingDone}</Alert>}
      {hasError && <Alert variant="danger">{hasError}</Alert>}
      {pricesHasError && <Alert variant="danger">{pricesHasError}</Alert>}
    </>
  );
};

export default ExchangeCard;
