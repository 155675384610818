import { Card } from "react-bootstrap";
import { styled } from "styled-components";

import { AllowanceResponse } from "../../types/Api";
import { StyledTd, TableContainer } from "../misc/TableContainer";

const StyledCard = styled(Card)`
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    margin: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
  }
`;

const InfoWrapper = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  margin-bottom: 2px;
`;

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-x: scroll;
`;

type AllowanceItemProps = {
  item: AllowanceResponse;
};

export const AllowanceItem = ({ item }: AllowanceItemProps) => {
  return (
    <StyledCard
      border="light"
      className="text-white bg-secondary mb-2 text-center"
    >
      <Card.Body className="px-0 py-0">
        <InfoWrapper>
          <span className="fw-bold d-flex align-items-center">
            {item.arbitrageName}
          </span>
        </InfoWrapper>
        <TableWrapper>
          <TableContainer
            bordered={false}
            striped={false}
            hover={false}
            wrapperClassName="mt-0"
            tableClassName="mx-2 my-0 mx-lg-0"
            header={[
              ["Asset", "100px"],
              ["Exchange", "55px"],
              ["Version", "120px"],
              ["Network", "230px"],
              ["Allowance units", "130px"],
              ["Allowance dollars", "130px"],
              ["Router", "70px"],
            ]}
            isEmpty={false}
          >
            {item.assets.map((asset) => (
              <tr key={asset.assetName}>
                <StyledTd>{asset.assetName}</StyledTd>
                <StyledTd>{asset.exchangeType}</StyledTd>
                <StyledTd>{asset.liquidityPoolVersion}</StyledTd>
                <StyledTd>{asset.networkPlatform}</StyledTd>
                <StyledTd>{asset.allowanceAmount}%</StyledTd>
                <StyledTd>{asset.allowanceDollarValue}</StyledTd>
                <StyledTd>{asset.routerAddress}</StyledTd>
              </tr>
            ))}
          </TableContainer>
        </TableWrapper>
      </Card.Body>
    </StyledCard>
  );
};
