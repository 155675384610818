import TransferCard from "./TransferCard";
import TransferTable from "./TransferTable";
import { useCurrentBalances } from "../../hooks";
import BalanceCurrent from "../balance/BalanceCurrent";

const TransferViewer = () => {
  const { currentBalanceGroups, isLoading, hasError, fetchItems, countdown } =
    useCurrentBalances();

  return (
    <>
      <BalanceCurrent
        currentBalanceGroups={currentBalanceGroups}
        countdown={countdown}
        isLoading={isLoading}
        fetchCurrentBalances={fetchItems}
        hasError={hasError}
      />
      <TransferCard />
      <TransferTable />
    </>
  );
};

export default TransferViewer;
