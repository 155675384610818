import { useMemo } from "react";
import { Alert } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { styled } from "styled-components";

import { useRecentTradeStoreData } from "../../hooks";
import { RecentTradeStoreDataResponse } from "../../types/Api";
import { findNetworkById } from "../../utils";
import Loader from "../misc/Loader";

const groupByNetworkPlatform = (data: RecentTradeStoreDataResponse[]) =>
  data.reduce(
    (acc, item) => {
      if (!acc[item.networkPlatform]) {
        acc[item.networkPlatform] = [];
      }
      acc[item.networkPlatform].push(item);
      return acc;
    },
    {} as Record<string, RecentTradeStoreDataResponse[]>,
  );

const getColorForNetworkPlatform = (networkPlatform: string) => {
  const colors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33F6",
    "#F6FF33",
    "#33FFF6",
    "#FF8333",
    "#8333FF",
    "#3FF833",
    "#F833FF",
  ];

  const index = (Number(networkPlatform) - 1) % colors.length;

  return colors[index];
};

const Container = styled.div`
  min-height: 420px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const RecentTradeStoreChart = () => {
  const { recentTradeStoreData, isLoading, hasError } =
    useRecentTradeStoreData();

  if (hasError) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }

  const dates = useMemo(
    () =>
      recentTradeStoreData?.map((store) => store.created.replace(/\.\d+/, "")),
    [recentTradeStoreData],
  );

  if (isLoading) {
    return <Loader height="452px" />;
  }

  const groupedData = groupByNetworkPlatform(recentTradeStoreData);

  return (
    <>
      <Container>
        <Line
          data={{
            labels: dates,
            datasets: Object.keys(groupedData).map((networkPlatform) => {
              const dataByDate = dates.map((date) => {
                const record = groupedData[networkPlatform].find(
                  (item) => item.created.replace(/\.\d+/, "") === date,
                );
                return record ? record.waitTimeInMiliseconds : null;
              });
              return {
                label: findNetworkById(Number(networkPlatform)),
                data: dataByDate,
                fill: false,
                backgroundColor: getColorForNetworkPlatform(networkPlatform),
                showLine: false,
              };
            }),
          }}
          options={{
            plugins: {
              legend: {
                display: true,
              },
            },
            maintainAspectRatio: false,
            scales: {
              x: {
                type: "time",
                time: {
                  unit: "hour",
                  displayFormats: {
                    hour: "dd/MM HH:MM",
                  },
                },
                grid: {
                  color: "#ffffff4d",
                },
                ticks: {
                  color: "#ffffff99",
                },
              },
              y: {
                grid: {
                  color: "#ffffff4d",
                },
                ticks: {
                  color: "#ffffff99",
                },
              },
            },
          }}
        />
      </Container>
    </>
  );
};

export default RecentTradeStoreChart;
